import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
{/*import {Link} from "react-router-dom";*/}
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';


const AboutSix = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top technology-section-padding-bottom-180">
            <div className="container">

                <div className="row">

                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/hero-image/dollar.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/hero-image/coin.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle="프리미엄 거래의 새로운 혁신"
                                title="기존에 널리 알려진 외환송금 형태의 재정거래가 아닙니다"
                            />

                            <p>바이봇이 보는 가상자산 마켓은 살아있는 황금의 바다와 같습니다. 전혀 새로운 차원의 거래 기술로 지금도 안정적인 수익이 발생하고 있으니까요. 저희는 운영자금을 받아서 운영하지 않습니다.</p>
                            <p><a></a></p>
                            <p>바이봇은 고객의 개인 거래소 지갑에 자산을 그대로 둔 채로, 거래 권한만 전달받아 작동하는 방식입니다. 바이봇은 고객의 가상자산을 외부로 인출할 수 없어 해킹으로부터도 안전하게 보호됩니다. </p>
                            <p><a></a></p>
                            <p>해외로 자산을 보내가면서 프리미엄 거래를 하는것 아니냐구요? 아닙니다. 바이봇만의 혁신적인 차익거래 기법으로 리스크는 0에 가까우면서 거래가 발생할때마다 확정적인 수익을 발생시키는 솔루션입니다. 바이봇은 가장 완벽하고 우아한 형태의 가상자산 차익거래 솔루션입니다. </p>

                            {/*<Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/"}>자세히 알아보기</Link>*/}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutSix;

const items = [
    {
    title: "2019.10",
    cardTitle: "",
    cardSubtitle:"(주)엔루트엠코리아 회사설립",
    cardDetailedText: "엔루트엠코리아는 가상자산 차익거래 아이디어를 기초 모델로 개발을 시작했습니다. 시장을 분석하고, 리스크를 없애고, 신뢰할 수 있는 이상적인 모델을 구상하여 긴 여정을 시작합니다.",
    media:{
      source: {
          url: "images/logo/logo_500x500.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.01",
    cardTitle: "",
    cardSubtitle: "차익거래 시스템 기반의 사업 로드맵 수립",
    cardDetailedText: "차익거래 플랫폼의 기초적인 사업 모델을 정립했습니다. 개인을 넘어 보다 많은 사람들이 자본주의의 혜택을 누릴 수 있는 시범적인 사업에 도전하기로 결정했습니다. 단순한 프로그램 구동을 넘어 공유경제 플랫폼으로 비즈니스를 기획하고 구체적인 계획이 만들어졌습니다.",
    media:{
      source: {
          url: "images/hero-image/roadmap.jpg"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.02",
    cardTitle: "",
    cardSubtitle: "ViCA 토큰 비즈니스 로드맵 수립",
    cardDetailedText: `투자를 받지 않는 전혀 새로운 개념의 공유금융 비즈니스를 기획했습니다.
        No ICO, No Pre-sale을 원칙으로 지금까지 그 누구도 보여줄 수 없었던 ERC-20 토큰 비즈니스의 유일무이한 가치를 제안합니다.
        차익거래는 시스템의 특성상 운영 자산이 클수록 이익이 커지는 구조입니다. 시스템 운영비용이 높아 혜택을 볼 수 없는 개인들을 위해 준비한 엔루트엠코리아의 해답입니다. 투자유치 목적의 토큰이 아닙니다. 모든 규제와 모든 검증을 마친 후에 비로소 시작 예정인 프로젝트입니다.`,
    media:{
      source: {
          url: "images/logo/vica.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.04",
    cardTitle: "",
    cardSubtitle: "차익거래 시스템 베타테스트 개시",
    cardDetailedText: "27개월의 R&D와 검증을 마친 차익거래 시스템의 첫 정식 가동이 시작되었습니다. 24/7 개념의 자동화 시스템의 실전 테스트가 시작되었습니다. 길고 지난한 시간을 견디며 드디어 한 발을 내딛었습니다.",
    media:{
      source: {
          url: "images/hero-image/server.jpg"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.05",
    cardTitle: "",
    cardSubtitle: "바이봇 브랜딩",
    cardDetailedText: "차익거래 소프트웨어의 정식 이름이 지어졌습니다.",
    media:{
      source: {
          url: "images/logo/vibot.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.06",
    cardTitle: "",
    cardSubtitle: "파트너십 체결",
    cardDetailedText: "(주)골드문인베스트와 바이봇을 이용한 가상자산 운용에 대한 파트너십을 체결했습니다.",
    media:{
      source: {
          url: "images/logo/goldmoon.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.06",
    cardTitle: "",
    cardSubtitle: "바이봇 운용자산 1,000ETH 돌파",
    cardDetailedText: "국내/해외 거래소 차익거래 설정 운용금액 1,000ETH를 돌파했습니다. 차익거래는 거래기법상 국내 거래소와 해외 거래소의 동시거래 형태로 자산이 운용됩니다. 정식 출시 1개월만에 이룬 쾌거입니다.",
    media:{
      source: {
          url: "images/logo/eth-diamond.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021.07",
    cardTitle: "",
    cardSubtitle: "세계 최초 실시간 유튜브 스트리밍 생중계 시작",
    cardDetailedText: "(주)골드문인베스트 회원들을 위한 거래 및 자산 현황을 확인할 수 있는 대시보드 유튜브 실시간 스트리밍 서비스를 개시했습니다. 차익거래 시스템 바이봇에 대한 엔루트엠코리아의 자신감입니다.",
    media:{
      source: {
          url: "images/logo/youtube_live.png"
        },
        type: "IMAGE"
    }
  },
  {
    title: "2021~",
    cardTitle: "",
    cardSubtitle: "(주)엔루트엠코리아는 이제 시작입니다.",
    cardDetailedText: "바이봇 시스템 고도화와 바이카 생태계를 위한 밑 작업이 시작됩니다. 이미 시스템에 대한 많은 협력업체와 관계자들의 검증이 완료되었습니다. 이제 우리가 꿈꾸던 공유금융의 가치를 입증하겠습니다.",
    media:{
      source: {
          url: "images/hero-image/just_start.jpg"
        },
        type: "IMAGE"
    }
  }
];

export default items;

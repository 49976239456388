import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import FunfactTwo from '../container/Funfact/vibot_funfact';
import AboutVibot from '../container/About/AboutVibot';
import ContactInformationThree from '../container/ContactInformation/ContactInformation_nrootm';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const Service = () => {
    return (
        <React.Fragment>
            <SEO title="서비스 || 바이봇" />
            <Header />
            <Breadcrumb 
                image="images/hero-image/service_vibot.jpg"
                title="가상자산 차익거래 솔루션, 바이봇"
                content="서비스"
                contentTwo="바이봇"
            />
            <AboutVibot />
            <FunfactTwo />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Service;




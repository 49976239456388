import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import AboutSix from '../container/About/AboutVica';
import WorkContainer from '../container/Work/WorkContainerVica';
import ContactInformationThree from '../container/ContactInformation/ContactInformation_nrootm';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const Service = () => {
    return (
        <React.Fragment>
            <SEO title="서비스 || 바이카" />
            <Header />
            <Breadcrumb 
                image="images/bg/finance.jpg"
                title="레버리지 공유금융, 바이카 프로젝트"
                content="서비스"
                contentTwo="바이카"
            />
            <AboutSix />
            <WorkContainer />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Service;




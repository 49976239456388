import {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import home from './pages/home';
import ideology from './pages/ideology';
import history from './pages/history';
import ci from './pages/ci';
import vibot from './pages/vibot';
import vica from './pages/vica';
import ethics from './pages/ethics';
import press_release from './pages/press_release';
import communication from './pages/communication';
import notice from './pages/notice';
import recruit from './pages/recruit';
//import timeline_data from './/timeline'

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';

function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={home}/>
              <Route path={`${process.env.PUBLIC_URL + "/ideology"}`} component={ideology}/>
              <Route path={`${process.env.PUBLIC_URL + "/history"}`} component={history}/>
              <Route path={`${process.env.PUBLIC_URL + "/ci"}`} component={ci}/>
              <Route path={`${process.env.PUBLIC_URL + "/vibot"}`} component={vibot}/>
              <Route path={`${process.env.PUBLIC_URL + "/vica"}`} component ={vica} />
              <Route path={`${process.env.PUBLIC_URL + "/ethics"}`} component ={ethics} />
              <Route path={`${process.env.PUBLIC_URL + "/press_release"}`} component ={press_release} />
              <Route path={`${process.env.PUBLIC_URL + "/communication"}`} component ={communication} />
              <Route path={`${process.env.PUBLIC_URL + "/notice"}`} component ={notice} />
              <Route path={`${process.env.PUBLIC_URL + "/recruit"}`} component ={recruit} />
              {/* <Route component ={NotFound} /> */}
            </Switch>
          </NavScrollTop>
      </Router>
  );
}

export default App;

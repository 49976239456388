import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';


const AboutSix = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top technology-section-padding-bottom-180">
            <div className="container">
                <div className="row">
                <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle="차익거래 수익금이 순환하는 공유경제"
                                title="바이카 토큰을 가지고 있는 것만으로 차익거래 수익이 발생합니다. "
                            />

                            <p>바이봇은 고정적인 차익거래 수익을 냅니다.<br/>바이카 토큰의 자산은 바이봇에서 운영됩니다.<br/>수익은 바이카 토큰의 회수에 씁니다.<br/>또한 수익은 운영자금을 늘려 수익을 극대화합니다. </p>
                            <p>바이카가 만드는 공유금융은 차익거래를 기반으로 자산이 쉬지않고 순환하는 `바이카의 풍선`을 원리로 작동합니다. </p>
                            <p>가장 우아하고, 직관적인 토큰 비즈니스를 완성하였습니다. </p>
                            <a href="https://vica.global" target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4">자세히 알아보기</div>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/hero-image/time.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/hero-image/doctor.jpg"} alt="" />
                                </Tilt>
                            </div>
                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutSix;

import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import {Chrono} from "react-chrono";
import Timeline_data from "../container/Timeline/timeline";

const BlogClassic = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아 || 회사연혁" />
            <Header />
            <Breadcrumb 
                image="images/hero-image/building.jpg"
                title="회사연혁"
                content="엔루트엠코리아"
                contentTwo="회사연혁"
            />
            <div className="container position-relative">
                <div style={{width: "100%", display: 'flex', justifyContent:'center', alignItems:'center'}}>
                    <Chrono 
                        items={Timeline_data}
                        mode="VERTICAL_ALTERNATING" 
                        cardHeight={350}
                        theme={{
                            primary: "#CDCDCD",
                            secondary: "#EEEEEE",
                            cardBgColor: "white",
                            cardForeColor: "#353535",
                            titleColor: "black"
                        }}
                        >
                        <div className="chrono-icons">
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                            <img src="images/icons/logo.png" alt="nrootm"/>
                        </div>
                </Chrono>
                </div>
            </div>
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BlogClassic;

import {useEffect, useRef} from "react";
import Parallax from 'parallax-js';

const CallToAction = () => {
    const sceneEl = useRef(null);
    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-bg-image section-padding-t110-b120 newsletter-section overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/newsletter.jpg)`}}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-12 m-auto">
                        <div className="cta-content text-center">
                            <div className={`section-title color-light text-center mb-0`} data-aos="fade-up">
                                <h2 className="sub-title"><p>엔루트엠코리아의 여정은 이제 시작되었습니다</p></h2>
                                <p className="sub-title">바이카의 철학과 바이봇의 금융공학,</p>
                                <p className="sub-title">그리고 그 모든것을 현실로 만들어낸 세계최고의 기술력.</p>
                                <p className="sub-title"><a></a></p>
                                <p className="title">엔루트엠코리아입니다.</p>
                                <p className="sub-title"><a></a></p>
                            </div>

                            <a href="http://docs.google.com/forms/d/1Yfu4faFTh1fNunWOZ-7W4lP3lj6JRnuKwAIqwwEwCNU" target="_blank" rel="noopener noreferrer">
                                <div className="btn btn-primary btn-hover-secondary mt-6">우리와 함께하기</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                    <img src={process.env.PUBLIC_URL + '/images/shape-animation/newsletter-shape.png'} alt="" />
                </span>
            </div>
        </div>
    )
}

export default CallToAction;

import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import ServiceIconBox from '../container/service/ServiceIconBoxEthic';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const HomeThree = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아 || 기업윤리" />
            <Header />
            <div className="page-title-section section section-padding-top overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "images/bg/ethics.jpg"})`}}>
            <div className="page-title">
                <div className="container">
                    <h1 className="title">기업윤리</h1>
                </div>
                <div className="page-breadcrumb position-static">
                <div className="container">
                    <ul className="breadcrumb justify-content-center">
                            <li className="current">Business Ethics</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <ServiceIconBox />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default HomeThree;

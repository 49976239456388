import React from 'react';
import SEO from '../components/SEO';
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';



const WorkDetails = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아 || CI/BI" />
            <Header />
            <Breadcrumb 
                image="images/hero-image/eye.jpg"
                title="CI"
                content="엔루트엠코리아"
                contentTwo="CI"
            />
            <div className="section section-padding">
                <div className="container">
                    <div className="row pt--100 pb--80">

                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="work-left work-details" data-aos="fade-up">
                                <div className="portfolio-main-info">
                                    <h2 className="title">Design </h2>
                                        <div className="details-list">
                                            <label>Logo</label>
                                        </div>
                                        <div className="details-list">
                                                <img className="w-100" src={`${process.env.PUBLIC_URL}/images/logo/logo_footer.png`} alt="Agency" />
                                            </div>
                                        <div className="work-details-list mt-12">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
                            <div className="work-left work-details mt-6">
                                <div className="work-main-info">
                                    <div className="work-content">
                                        <h6 className="title" data-aos="fade-up">공유금융의 단단한 기반</h6>
                                        <div className="desc mt-8">
                                        <div className="content mb-5" data-aos="fade-up">
                                            엔루트엠코리아의 기업 가치는 `공유`에서 시작됩니다. `기술`이 뒷받침 되는 `공유`는 신뢰 시스템을 통해서 구현됩니다. 공정한 배분과 정보공개, 이것은 저희의 자신감의 표현입니다. 로고 테두리의 `원`은 가장 안정적인 신뢰의 기반을 의미합니다. 신뢰를 기반으로 참여자 모두의 가치가 상승하는 형태를 뿌리를 형상화한 도형으로 표현했습니다.
                                        </div>
                                        <div className="content mb-5" data-aos="fade-up">
                                            NROOTM의 `ROOT`는 뿌리, N은 `None` , M은 `Meme` 을 의미합니다. 밈(Meme)은 개인이나 집단의 생각이나 믿음이 다른 사람들에게 전달되는 형태로 전 세계로 퍼져나가는 문화가 됩니다. 우리의 시작은 `아무것도 아닌 상태(None)`였을지라도, 단단한 뿌리의 역할을 하는 엔루트엠코리아의 철학으로 `공유금융`이라는 밈을 모두에게 퍼트리기 위한 의지의 표현입니다.
                                        </div>
                                        <div className="content mb-5" data-aos="fade-up">
                                            말로 계획을 이야기하는 것은 쉽습니다. 그 계획을 구체화 하는 것은 어렵고, 구체화된 것을 다시 구현하는 것은 훨씬 어렵습니다. 굳게 내린 뿌리는 나무가 되어 때로는 그늘이 되어주고, 열매도 나누게 됩니다. 그게 엔루트엠코리아가 바라는 미래이자 로고로 풀어낸 철학입니다.
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-layout-gallery mt-lg-20 mt-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="thumbnail" data-aos="fade-up">
                                    <img className="w-100" src={`${process.env.PUBLIC_URL}/images/hero-image/ci_4.jpg`} alt="Agency" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
                                <div className="thumbnail" data-aos="fade-up">
                                    <img className="w-100" src={`${process.env.PUBLIC_URL}/images/hero-image/ci_1.jpg`} alt="Agency" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

WorkDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([    
                PropTypes.string,
                PropTypes.number
            ])
        })
    })
}

export default WorkDetails;
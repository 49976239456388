import React from 'react';
import YoutubeBackground from 'react-youtube-background'
import {Link} from "react-router-dom";

const IntroTwo = () => {
    return (
            <YoutubeBackground
                videoId="3DSmrsvd950"
                overlay="rgba(0,0,0,.4)"
                className="intro-section section bg-video"
            >
                <div className="container">
                    <div className="row row-cols-lg-1 row-cols-1">

                        <div className="col align-self-center">
                            <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                <h2 className="title">공유금융의 혁신 </h2>
                                <div className="desc">
                                    <p>엔루트엠코리아가 만든 공유금융으로,</p>
                                    <p>당신의 시간을 소중히 지켜드리겠습니다</p>
                                </div>
                                <Link to={process.env.PUBLIC_URL + "/vibot"} className="btn btn-primary btn-hover-secondary">차익거래 솔루션 ViBOT 소개</Link>
                                <Link to={process.env.PUBLIC_URL + "/history"} className="btn btn-outline-white btn-hover-primary">엔루트엠코리아 소개 </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </YoutubeBackground>
    )
}


export default IntroTwo;

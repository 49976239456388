import React, {Fragment} from 'react';

const ProjectForm = () => {
    return (
        <Fragment>
        <div style={{width: "100%", display: 'flex', justifyContent:'left', alignItems:'left'}}>
            <a href="http://docs.google.com/forms/d/1Yfu4faFTh1fNunWOZ-7W4lP3lj6JRnuKwAIqwwEwCNU" target="_blank" rel="noopener noreferrer">
                <div className="btn btn-outline-light mt-xl-0 mt-lg-2 mt-md-2 mt-4">Contact Us</div>
            </a>
        </div>
        </Fragment>
    )
}

export default ProjectForm;

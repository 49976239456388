import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import PageBlog from '../container/BlogGrid/PageBlogNotice';
import CallToAction from '../container/CallToAction/CallToActionNotice';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const BlogGrid = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아 || 공지사항 언론보도" />
            <Header />
            <div className="page-title-section section section-padding-top overlay-two" style={{backgroundImage: `url(${process.env.PUBLIC_URL + "images/bg/press.jpg"})`}}>
            <div className="page-title">
                <div className="container">
                    <h1 className="title">공지사항</h1>
                </div>
                <div className="page-breadcrumb position-static">
                <div className="container">
                    <ul className="breadcrumb justify-content-center">
                            <li className="current">공지사항 및 언론보도</li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
            <PageBlog />
            <CallToAction />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BlogGrid;

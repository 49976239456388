import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Intro from '../container/IntroSlider/Intro';
import PageBlog from '../container/BlogGrid/PageBlogNotice';
import CallToAction from '../container/CallToAction/CallToActionNotice';
import FunfactVibot from '../container/Funfact/vibot_funfact';
import AboutVibot from '../container/About/AboutVibot';
import WorkContainerVica from '../container/Work/WorkContainerVica';
import AboutVica from '../container/About/AboutVica';
import ContactInformationNrootm from '../container/ContactInformation/ContactInformation_nrootm';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const HomeTwo = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아" />
            <Header />
            <Intro />
            <PageBlog />
            <CallToAction />
            <FunfactVibot />
            <AboutVibot />
            <WorkContainerVica />
            <AboutVica />
            <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="thumbnail" data-aos="fade-up">
                                    <img className="w-100" src={`${process.env.PUBLIC_URL}/images/hero-image/recruit1.jpg`} alt="Agency" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
                                <div className="thumbnail" data-aos="fade-up">
                                    <img className="w-100" src={`${process.env.PUBLIC_URL}/images/hero-image/recruit2.jpg`} alt="Agency" />
                                </div>
                            </div>
                        </div>
                    <p><a></a></p>
                    <p><a></a></p>
            </div>
            <ContactInformationNrootm />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default HomeTwo;




import React from 'react'
import {NavLink} from 'react-router-dom';

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/history"}><span className="menu-text">엔루트엠코리아</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/ideology"}><span className="menu-text">기업이념</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/history"}><span className="menu-text">회사연혁</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/ci"}><span className="menu-text">C.I</span></NavLink></li>
                    </ul>
                </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/vibot"}><span className="menu-text">서비스</span></NavLink>
                    <span className="menu-toggle"><i className="far fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/vibot"}><span className="menu-text">바이봇</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/vica"}><span className="menu-text">바이카</span></NavLink></li>
                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/ethics"}><span className="menu-text">기업윤리</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/notice"}><span className="menu-text">공지사항</span></NavLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/recruit"}><span className="menu-text">채용정보</span></NavLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar

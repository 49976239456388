import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import ServiceIdeology from '../container/service/Ideology';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const Service = () => {
    return (
        <React.Fragment>
            <SEO title="엔루트엠코리아 || 기업이념" />
            <Header />
            <Breadcrumb 
                image="images/hero-image/justice.jpg"
                title="기업이념"
                content="엔루트엠코리아"
                contentTwo="기업이념"
            />
            <ServiceIdeology />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Service;




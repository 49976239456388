import React, {Fragment} from 'react';

const ProjectForm = () => {
    return (
        <Fragment>
        <div style={{width: "100%", display: 'flex', justifyContent:'center', alignItems:'center'}}>
            <a href="http://docs.google.com/forms/d/1Yfu4faFTh1fNunWOZ-7W4lP3lj6JRnuKwAIqwwEwCNU" target="_blank" rel="noopener noreferrer">
                <div className="btn btn-primary btn-hover-secondary mt-xl-2 mt-lg-8 mt-md-6 mt-4">여기를 눌러 당신의 정보를 알려주세요.</div>
            </a>
        </div>
        </Fragment>
    )
}

export default ProjectForm;
